
  .card:hover{
    transform: scale(1.03);
  }
  .card{
    width: 20%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin: 8px;
  }
  img{
    width: 100%;
  }
  .img{
    background-color: rgb(111, 187, 36);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .card-btn{
    background-color: gray;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    width: 80%;
    padding: 4px;
  }
  .card-btn:hover{
    background-color:green;
    color:white;
  }
  
  @media screen and (max-width:900px){
    .card{
      width: 46%;
    }
  }
  
  @media screen and (max-width:400px){
    .card{
      width: 80%;
    }
  }